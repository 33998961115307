<template>
  <CyKpiWidgetsWidget
    v-bind="$attrs"
    :kpi="kpi"
    show-job
    v-on="$listeners">
    <template v-if="!_.isEmpty(kpi.data_set)">
      <CyKpiWidgetsNumber
        class="mb-3"
        :value="average"
        :format="formatDuration"
        success-delta-sign="-"
        :label="$t('average')"/>

      <ECharts
        class="bar-chart"
        theme="cycloid"
        autoresize
        :option="options"/>
    </template>
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot"/>
      </template>
    </template>
  </CyKpiWidgetsWidget>
</template>

<script>
import CyKpiWidgetsNumber from '@/components/kpi-widgets/number.vue'
import CyKpiWidgetsWidget from '@/components/kpi-widgets/widget.vue'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import 'moment-duration-format'

export default {
  name: 'CyKpiWidgetsBuildTime',
  components: {
    CyKpiWidgetsNumber,
    CyKpiWidgetsWidget,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    average: 0,
  }),
  computed: {
    options () {
      return {
        series: [{
          type: 'bar',
          data: this.kpi.data_set.slice(1),
          markLine: {
            data: [{
              yAxis: this.average,
            }],
            label: { show: false },
            symbol: 'none',
            silent: true,
          },
        }],
        grid: {
          top: 12,
          bottom: 0,
          left: 20,
          right: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            formatter: this.formatDateLabel,
          },
          interval: 1000 * 3600 * 24, // One day interval in ms
        },
        yAxis: {
          type: 'time',
          name: this.$t('yAxisLegend'),
          nameGap: 28,
          splitNumber: 5,
          splitLine: { show: true },
          min: this.getMinYAxisValue,
          max: this.getMaxYAxisValue,
          axisLabel: {
            formatter: this.formatDurationLabel,
            showMinLabel: true,
            showMaxLabel: true,
          },
        },
      }
    },
  },
  watch: {
    'kpi.data_set': {
      immediate: true,
      handler (dataSet) {
        if (_.isEmpty(dataSet)) return
        this.setDataFromSource(dataSet)
      },
    },
  },
  methods: {
    formatDateLabel (date) {
      return moment(Number(date)).format('MM/DD') // eslint-disable-line you-dont-need-momentjs/format
    },
    formatDuration (milliseconds) {
      return moment.duration(milliseconds).format('*mm[m] ss[s]') // eslint-disable-line you-dont-need-momentjs/format
    },
    formatDurationLabel (milliseconds) {
      return moment.duration(milliseconds).format('*mm:ss') // eslint-disable-line you-dont-need-momentjs/format
    },
    getMinYAxisValue ({ min }) {
      // Returns the closest minute mark (expressed in ms) below the passed minimal value. 60,000ms = 1min
      return Math.floor(min / 60000) * 60000
    },
    getMaxYAxisValue ({ max }) {
      // Returns the closest minute mark (expressed in ms) above the passed maximal value. 60,000ms = 1min
      return Math.max(Math.ceil(max / 60000), 1) * 60000
    },
    setDataFromSource ([keys, ...rows]) {
      const timeIndex = keys.indexOf('time')
      this.average = _.chain(rows)
        .map((row) => row[timeIndex])
        .filter((time) => time > 0)
        .mean()
        .round()
        .value()
    },
  },
  i18n: {
    messages: {
      en: {
        average: 'Average',
        yAxisLegend: 'Execution time (minutes)',
      },
      es: {
        average: 'Promedio',
        yAxisLegend: 'Tiempo de ejecución (minutos)',
      },
      fr: {
        average: 'Moyenne',
        yAxisLegend: `Temps d'exécution (minutes)`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.bar-chart {
  width: 100%;
  height: 188px;
}

.numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
</style>
