<template>
  <div class="number">
    <label class="number__label">{{ label }}</label>
    <div class="number__value">
      <slot name="prepend"/>
      <span>{{ format(value) }}</span>
      <template v-if="progression">
        <v-icon
          :size="16"
          :class="['number__icon', `number__icon--${progression.iconColor}`]">
          {{ progression.icon }}
        </v-icon>
        <span :class="['number__delta', `number__delta--${progression.iconColor}`]">{{ progression.delta }}</span>
      </template>
    </div>
  </div>
</template>

<script>
export const successDeltaSigns = ['+', '-']

export default {
  name: 'CyKpiWidgetsNumber',
  props: {
    value: {
      type: Number,
      required: true,
    },
    prevValue: {
      type: Number,
      default: null,
    },
    format: {
      type: Function,
      default: (value) => value,
    },
    label: {
      type: String,
      required: true,
    },
    successDeltaSign: {
      type: String,
      default: '+',
      validator: (successDeltaSign) => successDeltaSigns.includes(successDeltaSign),
    },
  },
  computed: {
    progression () {
      if (!_.isNumber(this.prevValue)) return false
      const delta = this.getDeltaPercentage(this.value, this.prevValue)
      return {
        '-1': {
          icon: 'arrow_downward',
          iconColor: this.successDeltaSign === '+' ? 'error' : 'success',
          delta: `${Math.abs(delta).toFixed(2)}%`,
        },
        0: {
          icon: 'arrow_forward',
          iconColor: 'neutral',
          delta: '0%',
        },
        1: {
          icon: 'arrow_upward',
          iconColor: this.successDeltaSign === '+' ? 'success' : 'error',
          delta: `${delta.toFixed(2)}%`,
        },
      }[Math.sign(delta)]
    },
  },
  methods: {
    getDeltaPercentage (value, prevValue) {
      if (prevValue === 0) return 0
      return ((value - prevValue) / prevValue) * 100
    },
  },
}
</script>

<style lang="scss" scoped>
.number {
  &__value {
    display: flex;
    align-items: flex-end;
    color: cy-get-color("primary", "light-1");
    font-size: $font-size-h3;
    font-weight: $font-weight-medium;
    line-height: $line-height-heading;
  }

  &__icon {
    margin-bottom: 4px;
    margin-left: 4px;
  }

  &__label {
    color: cy-get-color("primary", "light-3");
    font-size: $font-size-sm;
  }

  &__delta {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: $font-weight-bolder;
    line-height: $line-height-heading;
  }

  &__delta,
  &__icon {
    &--success {
      color: cy-get-color("success");
    }

    &--error {
      color: cy-get-color("error");
    }

    &--neutral {
      color: cy-get-color("grey", "dark-1");
    }
  }
}
</style>
