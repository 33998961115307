<template>
  <article :class="['pt-4', 'widget', 'overflow-hidden', { 'kpi-error': kpi.error }]">
    <v-skeleton-loader
      v-if="loading"
      type="card-heading,list-item-three-line,image,list-item"/>
    <template v-else>
      <header class="mb-3 px-4">
        <div class="widget__header">
          <h2 class="widget__title mb-2 mr-4">
            {{ kpi.name }}
          </h2>
          <slot name="actions">
            <div class="widget__actions">
              <CyButton
                v-for="({ icon, onClick }, key) in menuItems"
                :key="key"
                icon-only
                :icon="icon"
                theme="grey"
                variant="tertiary"
                sm
                @click="onClick"/>
              <CyButton
                icon-only
                :icon="kpi.favorite ? 'star' : 'star_outline'"
                theme="primary"
                variant="tertiary"
                sm
                :loading="!canEdit || isSaving"
                @click="toggleFavorite(!kpi.favorite)"/>
              <CyMenu
                bottom
                left
                offset-y
                :items="$static.dropdownMenuItems">
                <template #activator="{ on }">
                  <CyButton
                    variant="tertiary"
                    theme="primary"
                    icon="more_horiz"
                    icon-only
                    sm
                    v-on="on"/>
                </template>
              </CyMenu>
            </div>
          </slot>
          <CyTooltip
            bottom
            theme="error">
            <template #activator="{ on }">
              <CyButton
                v-if="kpi.error"
                icon-only
                icon="error"
                theme="error"
                variant="tertiary"
                sm
                v-on="on"/>
            </template>
            <div v-html="$sanitizeHtml($t('kpis.anErrorOccurred'))"/>
            <div>{{ kpi.error }}</div>
          </CyTooltip>
        </div>

        <div class="widget__legend">
          <div
            v-if="showEnvironment"
            class="widget__legend-item">
            <CyAvatar
              :item="_.find(envs, { canonical: kpi.environment_canonical })"
              class="mr-2"
              sm/>
            <div class="widget__legend-details">
              <label class="widget__label">{{ $t('Environment') }}</label>
              {{ kpi.environment_canonical }}
            </div>
          </div>

          <template v-if="showJob">
            <div class="widget__legend-item">
              <CyAvatar
                :item="_.find(envs, { canonical: kpi.environment_canonical })"
                class="mr-2"
                sm/>
              <div class="widget__legend-details">
                <label class="widget__label">{{ $t('untranslated.pipeline') }}</label>
                <router-link
                  :to="{
                    name: 'pipeline',
                    params: {
                      orgCanonical,
                      projectCanonical: kpi.project_canonical,
                      pipelineCanonical: kpi.pipeline_name,
                      envCanonical: kpi.environment_canonical,
                    },
                  }">
                  {{ kpi.pipeline_name }}
                </router-link>
              </div>
            </div>

            <v-icon
              size="18"
              class="widget__legend-icon">
              keyboard_arrow_right
            </v-icon>

            <div class="widget__legend-item">
              <div class="widget__legend-details">
                <label class="widget__label">{{ $t('untranslated.job') }}</label>
                <router-link
                  :to="{
                    name: 'jobs',
                    params: {
                      orgCanonical,
                      projectCanonical: kpi.project_canonical,
                      pipelineCanonical: kpi.pipeline_name,
                      jobCanonical: kpi.job_name,
                      envCanonical: kpi.environment_canonical,
                    },
                  }">
                  {{ kpi.job_name }}
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </header>

      <div class="widget__content px-4 pb-4">
        <slot name="default">
          <div class="widget__empty-state">
            <span>{{ $t('noData') }}</span>
          </div>
        </slot>
      </div>

      <template v-if="$slots.footer">
        <v-divider/>
        <footer class="widget__footer pa-4">
          <slot name="footer"/>
        </footer>
      </template>

      <!-- Edit dialog -->
      <CyFormsWidget
        :key="kpi.updated_at"
        ref="formDialog"
        :project-canonical="kpi.project_canonical"
        :kpi="kpi"
        class="d-none"/>
    </template>
  </article>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CyFormsWidget from '@/components/forms/widget.vue'

export default {
  name: 'CyKpiWidgetsWidget',
  components: {
    CyFormsWidget,
  },
  props: {
    kpi: {
      type: Object,
      default: () => ({}),
    },
    showEnvironment: {
      type: Boolean,
      default: false,
    },
    showJob: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isSaving: false,
  }),
  computed: {
    ...mapState({
      errors: (state) => state.organization.project.kpi.errors.kpi,
      errorsFavorite: (state) => state.organization.project.kpi.errors.favorite,
    }),
    ...mapGetters('organization/project', {
      projectEnvs: 'envs',
    }),
    $static () {
      const { $refs, kpi: { favorite }, toggleFavorite, remove } = this
      const canUpdateKPI = this.$cycloid.permissions.canDisplay('UpdateKPI')
      const canDeleteKPI = this.$cycloid.permissions.canDisplay('DeleteKPI')

      return {
        dropdownMenuItems: (() => [
          ...(canUpdateKPI
            ? [
                {
                  icon: 'mdi-pencil',
                  label: 'Edit',
                  action: () => { $refs.formDialog.open() },
                },
              ]
            : []
          ),
          {
            icon: favorite ? 'star_outline' : 'star',
            label: favorite ? this.$t('removeFavorite') : this.$t('addFavorite'),
            action: () => { toggleFavorite(!favorite) },
          },
          ...(canDeleteKPI
            ? [
                {
                  icon: 'delete',
                  label: 'Delete',
                  action: () => { remove() },
                },
              ]
            : []
          ),
        ])(),
      }
    },
    envs () {
      const { projectEnvs } = this
      if (_.isEmpty(projectEnvs)) return this.kpi.envs
      return projectEnvs
    },
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization/project/kpi', [
      'DELETE_KPI',
      'SET_FAVORITE_KPI',
      'UNSET_FAVORITE_KPI',
    ]),
    async toggleFavorite (targetState) {
      this.$toggle.isSaving(true)
      const action = targetState ? this.SET_FAVORITE_KPI : this.UNSET_FAVORITE_KPI
      const eventName = targetState ? 'favorited' : 'unfavorited'
      await action(this.kpi)
      this.$toggle.isSaving(false)
      if (_.isEmpty(this.errorsFavorite)) this.$emit(eventName)
      else this.SHOW_ALERT({ type: 'error', content: this.errorsFavorite })
    },
    async remove () {
      this.$toggle.isSaving(true)
      await this.DELETE_KPI(this.kpi)
      this.$toggle.isSaving(false)
      if (!_.isEmpty(this.errors)) this.SHOW_ALERT({ type: 'error', content: this.errors })
    },
  },
  i18n: {
    messages: {
      en: {
        noData: 'No data to display',
        addFavorite: 'Add to favorites',
        removeFavorite: 'Remove from favorites',
      },
      es: {
        noData: 'No hay información para mostrar',
        addFavorite: 'Agregar a favoritos',
        removeFavorite: 'Eliminar de favoritos',
      },
      fr: {
        noData: 'Aucune donnée à afficher',
        addFavorite: 'Ajouter aux favoris',
        removeFavorite: 'Supprimer des favoris',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.widget {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  transition: border-color 0.3s ease;
  border: 1px solid cy-get-color("primary", "light-4");
  border-radius: 8px;
  background-color: cy-get-color("white");
  color: cy-get-color("primary");

  &__actions {
    display: flex;
    margin-left: auto;
    transition: opacity 0.3s ease;
    opacity: 0;

    .v-icon {
      color: cy-get-color("primary");
    }

    .v-btn.v-btn.cy-btn.cy-btn__theme--grey:not(:hover, :focus) {
      color: cy-get-color("grey");
    }
  }

  &:hover {
    border-color: cy-get-color("primary", "light-2");

    .widget__actions {
      opacity: 1;
    }
  }

  &__header {
    display: flex;
  }

  &__title {
    margin-right: 24px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: $font-weight-bolder;
    line-height: 1.2;
  }

  &__legend {
    display: flex;
  }

  &__legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__legend-icon {
    align-self: flex-end;
    color: cy-get-color("primary", "light-3");
  }

  &__legend-details {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.5;
  }

  &__label {
    color: cy-get-color("primary", "light-2");
    font-size: 10px;
    line-height: 1.2;
  }

  &__content {
    flex-grow: 1;
  }

  &__empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 64px;
    border-radius: 4px;
    background-color: cy-get-color("primary", "light-5");
    color: cy-get-color("primary", "light-2");
    text-align: center;
  }
}

.kpi-error {
  border-color: cy-get-color("error");

  &:hover {
    border-color: cy-get-color("error");
  }
}
</style>
