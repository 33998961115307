<template>
  <CyKpiWidgetsWidget
    v-bind="$attrs"
    :kpi="kpi"
    show-job
    v-on="$listeners">
    <template v-if="!_.isEmpty(kpi.data_set)">
      <CyKpiWidgetsNumber
        class="mb-3"
        :value="total"
        :label="$t('totalBuilds')"/>
      <ECharts
        class="line-chart"
        theme="cycloid"
        autoresize
        :option="options"/>
    </template>
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot"/>
      </template>
    </template>
  </CyKpiWidgetsWidget>
</template>

<script>
import CyKpiWidgetsNumber from '@/components/kpi-widgets/number.vue'
import CyKpiWidgetsWidget from '@/components/kpi-widgets/widget.vue'

import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyKpiWidgetsBuildFrequency',
  components: {
    CyKpiWidgetsNumber,
    CyKpiWidgetsWidget,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    total: 0,
    max: 0,
  }),
  computed: {
    options () {
      return {
        series: [{
          type: 'line',
          data: this.kpi.data_set.slice(1),
        }],
        grid: {
          top: 12,
          bottom: 0,
          left: 24,
          right: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            formatter: this.formatDateLabel,
          },
          interval: 1000 * 3600 * 24, // One day interval in ms
        },
        yAxis: {
          type: 'value',
          name: this.$t('yAxisLegend'),
          nameGap: (this.max.toString().length - 1) * 7 + 4, // properly space the axis name depending on the labels size
          minInterval: 1,
          splitNumber: 7,
          splitLine: { show: true },
        },
        tooltip: {
          trigger: 'axis',
          formatter: this.formatTooltip,
        },
      }
    },
  },
  watch: {
    'kpi.data_set': {
      immediate: true,
      handler (dataSet) {
        if (_.isEmpty(dataSet)) return
        this.setDataFromSource(dataSet)
      },
    },
  },
  methods: {
    formatDateLabel (date) {
      return moment(Number(date)).format('MM/DD') // eslint-disable-line you-dont-need-momentjs/format
    },
    formatTooltip ([{ data: [date, total] }, ...rest]) {
      const formattedDate = moment(date).format('dd, MMM Do YYYY') // eslint-disable-line you-dont-need-momentjs/format
      return `${formattedDate}<br><b>${this.$tc('buildCount', total, { total })}</b>`
    },
    setDataFromSource ([keys, ...rows]) {
      const countIndex = keys.indexOf('total')
      this.total = _.chain(rows)
        .map((row) => row[countIndex])
        .sum()
        .value()
      this.max = _.chain(rows)
        .map((row) => row[countIndex])
        .max()
        .value()
    },
  },
  i18n: {
    messages: {
      en: {
        buildCount: '0 builds | 1 build | {total} builds',
        totalBuilds: 'Total builds',
        yAxisLegend: 'Number of builds',
      },
      es: {
        buildCount: '0 compilaciónes | 1 compilación | {total} compilaciónes',
        totalBuilds: 'Compilaciónes totales',
        yAxisLegend: 'Número de compilaciónes',
      },
      fr: {
        buildCount: '0 build | 1 build | {total} builds',
        totalBuilds: 'Total des builds',
        yAxisLegend: 'Nombre de builds',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.line-chart {
  width: 100%;
  height: 188px;
}
</style>
